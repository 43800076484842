import React from 'react';





const RomsGrid = ({ roms }) => {


const searchArray=(arr, name)=>{
  var found=null;
  arr.forEach(element => {
    if(element===name)
    {
      found = element;
    }
    
  });
  return found
}

  const romCategories = [...new Set(roms.map(rom => rom.motion))];
  var rf = searchArray(romCategories,"RightShoulderFlexion")
  var ra = searchArray(romCategories,"RightShoulderAbduction")
  var rs = searchArray(romCategories,"RightShoulderScaption")
  var lf = searchArray(romCategories,"LeftShoulderFlexion")
  var la = searchArray(romCategories,"LeftShoulderAbduction")
  var ls = searchArray(romCategories,"LeftShoulderScaption")
  var bs = searchArray(romCategories,"BackFlexion")

  var rearranged=[rf,ra,rs,lf,la,ls,bs]
  rearranged= rearranged.filter(n => n)
  return rearranged.map((category, i) => (
    <div className="goals" key={i}>
      <h4>{category} <span className="small">{roms.find(rom => rom.motion.motion_id===undefined? rom.motion === category:rom.motion.motion_id === category).calculation_source == 0 && "(estimated)"}</span></h4>
      <div className="small">
        <h4>MIN: <b>{Math.round(roms.filter(rom=>rom.motion.motion_id===undefined? rom.motion === category:rom.motion.motion_id === category).reduce((a, b) => a.min < b.min ? a : b).min)}</b> </h4>
        <h4>MAX: <b>{Math.round(roms.filter(rom=>rom.motion.motion_id===undefined? rom.motion === category:rom.motion.motion_id === category).reduce((a, b) => a.max > b.max ? a : b).max)}</b></h4>
      </div>


    </div>
  ))
}

export default RomsGrid;