import React from 'react'
import Divider from './Divider.tsx';
import EditCoachingPlanHead, { ViewState } from './EditCoachingPlanHead.tsx';
import BonusSection from './BonusSection.tsx';
import RoutineSection from './RoutineSection.tsx';


const FutureWeekView = ({selectedWeek,name, activityStats,  weekOptions,coachingPlan,changeWeek,goToCurrentWeek, saveChanges,addWeeks, setActivity, setDuration, constPlan=null,  setOptionBonus=null, setOptionRoutine=null}) => {
  return (
    <div className='w-[100%]  p-10 flex-col justify-center items-center gap-y-6 inline-flex '>
    <div id="head" className='w-full  justify-center items-center gap-x-3 inline-flex '>
    <EditCoachingPlanHead setSelectedWeek={changeWeek} addWeeks={addWeeks} goToCurrent={goToCurrentWeek} saveChanges={saveChanges}  weekOptions={weekOptions} selectedWeek={selectedWeek} state={ViewState.Pending}  />
    </div>
    <div id="divider" className='w-full  justify-center items-center gap-x-3 inline-flex bg-green-100  '>
    <Divider label={"Assignments"}/>
</div>
<div id="divider" className='w-full  justify-center items-center gap-3 inline-flex  rounded-[10px] shadow border border-gray-200 '>
    <RoutineSection setOption={setOptionRoutine}  setActivity={null} setDuration={null} activityStats state={ViewState.Pending} firstName={name}/>
</div>
<div id="divider" className='w-full  justify-center items-center gap-3 inline-flex  rounded-[10px] shadow border border-gray-200 '>
    <BonusSection setOption={setOptionBonus}  constPlan={constPlan} setActivity={setActivity} setDuration={setDuration} key={coachingPlan}  state={ViewState.Pending} activityStats={activityStats} selectedWeek={selectedWeek} coachingplan={coachingPlan}/>
</div>

</div>  )
}

export default FutureWeekView