import React, { useState, Fragment, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { element } from 'prop-types';
import { set } from 'lodash';


  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

const CustomDropDown = ({options=(null||[]), key, locked=false ,jumpbackToDefault=false,onchange, truncate=false, badgePercent=true, right=true, defaultValue=null, badgeColors=null, badgeText=null, maxH=null, posBottom=true , fullwidth=false, customwidth=-1 ,customBadgePadding="", textcolor=" text-gray-700 font-semibold ",nameLimit=0, borderColor=""}) => {

    const [select, setSelect] = useState(options[0])
    const [changed, setChanged] = useState(false)
    const [init, setInit] = useState(true)
    const [refresh, setRefresh] = useState(false)
    const [JumpSelect, setJumpSelect] = useState(options[0])





   useEffect(()=>{},[key])

    useEffect(()=>{
        if(  select!==null&&!init&&select!==undefined&&onchange!==null&&onchange!==undefined && options!==null &&options!==undefined&& options)
        {

            onchange(select.value)
            if(jumpbackToDefault){setSelect(null)}
            
            
        }

        if(init)
        {setInit(false)}
        
       

        
        
    
      },[select])

      useEffect(()=>{
        
        if((options!==null &&options!==undefined&& options&&defaultValue!==null&&changed===false)||refresh)
        {
var def={};
        options.forEach(element => {
          if(defaultValue===element.value){
            def=element;
          }
              
            });
            if(def!==options[0]){

            setSelect(def)
            setJumpSelect(def)

            }
            else{
              setSelect(def)
            }
        }
        
        
    
      },[defaultValue,refresh,options])



  return (
    <React.Fragment> { (options!==null&options.length>0?<Listbox value={!jumpbackToDefault? select:JumpSelect} onChange={setSelect}  className="w-full">
      {({ open }) => (
        
        <span className="w-full" >
          <div className={"relative  "+("w-full")}>
            <Listbox.Button className={"relative "+("w-full")+" cursor-default  border "+(borderColor===""?"border-gray-300":borderColor)+" justify-start items-center gap-2 inline-flex   py-2.5  pl-3 pr-10 text-left "+(locked?"text-gray-400":textcolor)+" text-sm  shadow ring-0 ring-inset ring-gray-300 focus:outline-none focus:ring-2  basis-0 self-stretch rounded-lg font-['Inter'] "+(locked?"bg-gray-50 ":"bg-white ")+(locked?"focus:ring-gray-300":"focus:ring-brand-500")}>
              <span className="flex font-semibold	 items-center">
               {(!jumpbackToDefault?select.name!==undefined:JumpSelect.name!==undefined)&& <span className=" block truncate">{!jumpbackToDefault? (nameLimit===0? select.name:(select.name.length<=nameLimit?select.name:select.name.slice(0, nameLimit)+"...")):(nameLimit===0? JumpSelect.name:(JumpSelect.length<=nameLimit?JumpSelect.name:JumpSelect.name.slice(0, nameLimit)+"..."))}</span>}             
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-2 flex items-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open&&!locked}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              
            >
              <Listbox.Options className={"absolute font-['Inter'] "+(right?"right-0":"left-0")+"	"+(fullwidth?"w-full":(customwidth>0?"w-"+customwidth+" ":""))+"  z-10 "+(posBottom?"mt-1":"bottom-0 mb-10")+"   overflow-y-auto overflow-x-hidden	  rounded-md bg-white  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm "+(maxH!==null?maxH:"max-h-56")}>
                {options.map((element) => (
                  <Listbox.Option
                    key={element.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-brand-500 text-white' : ((!jumpbackToDefault?select.name===element.name:jumpbackToDefault.name===element.name)?"text-brand-700":textcolor),
                        'relative cursor-default select-none py-2 pl-3 pr-9 w-full  whitespace-nowrap '
                      )
                    }
                    value={element}
                  >
                    {({ selected, active }) => (
                      <span className='w-full'>
                                             

                        <div className="inline-flex items-center whitespace-nowrap	gap-x-2">
                         { element.img!==undefined&& <img src={element.img} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" />}
                          {
                          
                          element.name!==undefined&&<span
                            className={classNames((selected||(!jumpbackToDefault?select.name===element.name:jumpbackToDefault.name===element.name)) ? 'font-semibold ' : 'font-normal', 'ml-3  ',(customBadgePadding!==""?customBadgePadding:'mr-10 '))}
                          >
                            {element.name}
                          </span>
                          }


                          {

                            element.badge!==undefined&& <span className="absolute right-0 mr-2 ml-2">{  
                              (<div className={"px-2 py-0.5  rounded-full gap-x-1  justify-start items-center inline-flex "+(badgeColors[element.badge.color])}>
                                          {element.badge.img!==undefined&&<img className="w-3 h-3 relative" src={element.badge.img}/>}

                                         <div className={"text-center  text-xs font-medium font-['Inter'] leading-[18px] "+badgeText[element.badge.color]}>{badgePercent? Math.round(element.badge.value*100)+"%":element.badge.value}</div>
                                     </div>)
                               }</span>
                          }
                          {(selected||(!jumpbackToDefault?select.name===element.name:jumpbackToDefault.name===element.name)) ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-blue-600',
                              'inset-y-0  flex items-center '
                            )}
                          >
                            {/*<CheckIcon className="h-5 w-5" aria-hidden="true" />*/}
                          </span>
                        ) : null}
                        </div>

                       
                      </span>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </span>
      )}
    </Listbox>:
  <Listbox value={!jumpbackToDefault? select:JumpSelect} onChange={setSelect}  className="w-full">
  {({ open }) => (
    <span className=' w-full'>
      <div className="relative w-full ">
      <Listbox.Button className={"relative w-full cursor-default  border "+(borderColor===""?"border-gray-300":borderColor)+" justify-start items-center gap-2 inline-flex  bg-white py-2.5  pl-3 pr-10 text-left "+textcolor+" text-sm  shadow ring-0 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-brand-500 basis-0 self-stretch rounded-lg font-['Inter']"}>
      <span className="flex font-semibold	 items-center">
       <span className=" block truncate">No Options Found</span>           
      </span>
     
    </Listbox.Button>

        
      </div>
    </span>
  )}
</Listbox>)}
    </React.Fragment>
  )
}

export default CustomDropDown