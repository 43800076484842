import React, { forwardRef, useImperativeHandle } from 'react'
import { useState,useEffect, useRef} from 'react'
import { CheckIcon, ChevronUpIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

const SectionDivider = ({children,label, locked=false, warningLock=false, toggle=false }) => {
    const [opened,setOpened]= useState(locked?true:false);
    const clicker= useRef(null)
  
    useEffect(()=>{
        if(!locked &&opened &&clicker.current!==null){
        clicker.current.click();
        }
    },[toggle])

    useEffect(()=>{console.log(opened)},[opened])


    return (
        <div className='w-full flex-col'>
    <div className={"inline-flex relative mb-4 mt-2 items-center justify-center w-full py-2"}>
        <hr className="w-full h-0.5 bg-gray-200 border-0 rounded "/>
        <div ref={clicker} onClick={()=>{
                if(!locked){
                    setOpened(!opened)
                }
            }
            } className={"inline-flex  absolute px-2 -translate-x-1/2 bg-white left-1/2 text-center text-gray-900 text-lg font-semibold font-['Inter'] leading-tight " +(locked?"":"cursor-pointer")}>
           {warningLock&&<img src="/icons/WarningLock.svg" className='h-5 w-5 pr-1' />}
           {label}
           {!locked?(opened?<ChevronDownIcon className="h-5 w-5 translate-y-1 text-gray-700 relative" aria-hidden="true" />:<ChevronRightIcon className="h-5 w-5 translate-y-0.5 text-gray-700 relative" aria-hidden="true" />):""}
        </div>
    </div >
    <div className={(opened?"visible ":"hidden ")+("flex-col")}>{children}</div>
    </div>
      )
}

export default SectionDivider