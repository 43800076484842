import React from 'react'
import Divider from './Divider.tsx';
import EditCoachingPlanHead, { ViewState } from './EditCoachingPlanHead.tsx';
import BonusSection from './BonusSection.tsx';
import RoutineSection from './RoutineSection.tsx';

const PastWeekView = ({activityStats,selectedWeek,name, weekOptions, coachingPlan,changeWeek,goToCurrentWeek, personalRoutine, routineScoreData, constPlan=null, constRoutine=null}) => {
  return (
    <div className='w-[100%]  p-10 flex-col justify-center items-center gap-y-6 inline-flex '>
    <div id="head" className='w-full  justify-center items-center gap-x-3 inline-flex  '>
    <EditCoachingPlanHead goToCurrent={goToCurrentWeek} coachingPlan={coachingPlan} setSelectedWeek={changeWeek}  weekOptions={weekOptions}  selectedWeek={selectedWeek} state={ViewState.Completed}  />

    </div>
    <div id="divider" className='w-full  justify-center items-center gap-x-3 inline-flex bg-green-100  '>
    <Divider label={"Assignments"}/>
</div>
<div id="divider" className='w-full  justify-center items-center gap-3 inline-flex  rounded-[10px] shadow border border-gray-200 '>
    <RoutineSection constRoutine={constRoutine} setActivity={null} key={routineScoreData} setDuration={null} activityStats={activityStats} state={ViewState.Completed} firstName={name} routineBadgeData={routineScoreData} selectedWeek={selectedWeek} personalRoutine={personalRoutine}/>
</div>
<div id="divider" className='w-full  justify-center items-center gap-3 inline-flex  rounded-[10px] shadow border border-gray-200 '>
    <BonusSection constPlan={constPlan}  setActivity={null} key={coachingPlan} setDuration={null} state={ViewState.Completed} activityStats={activityStats} selectedWeek={selectedWeek} coachingplan={coachingPlan}/>
</div>

</div>  )
}

export default PastWeekView