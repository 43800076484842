import React from 'react'
import Divider from './Divider.tsx';
import EditCoachingPlanHead, { ViewState } from './EditCoachingPlanHead.tsx';
import BonusSection from './BonusSection.tsx';
import RoutineSection from './RoutineSection.tsx';

const CompletedPastWeekView = ({activityStats=null,selectedWeek, weekOptions, coachingPlan,changeWeek,StartNewPlan,  personalRoutine, name, routineScoreData,  constPlan=null,constRoutine=null}) => {
  return (
    <div className='w-[100%]  p-10 flex-col justify-center items-center gap-y-6 inline-flex '>
    <div id="head" className='w-full  justify-center items-center gap-x-3 inline-flex '>
    <EditCoachingPlanHead startNewPlan={StartNewPlan} setSelectedWeek={changeWeek} coachingPlan={coachingPlan} selectedWeek={selectedWeek} state={ViewState.CompletedPost} weekOptions={weekOptions}   />
    </div>
    <div id="divider" className='w-full  justify-center items-center gap-x-3 inline-flex bg-green-100  '>
    <Divider label={"Assignments"}/>
</div>
<div id="divider" className='w-full  justify-center items-center gap-3 inline-flex  rounded-[10px] shadow border border-gray-200 '>
    <RoutineSection constRoutine={constRoutine} activityStats={activityStats} key={routineScoreData}  setActivity={null} setDuration={null} state={ViewState.CompletedPost} firstName={name} routineBadgeData={routineScoreData} selectedWeek={selectedWeek} personalRoutine={personalRoutine}/>
</div>
<div id="divider" className='w-full  justify-center items-center gap-3 inline-flex  rounded-[10px] shadow border border-gray-200 '>
    <BonusSection constPlan={constPlan} activityStats={activityStats} key={coachingPlan}  setActivity={null} setDuration={null} state={ViewState.CompletedPost} selectedWeek={selectedWeek} coachingplan={coachingPlan}/>
</div>

</div>  )
}

export default CompletedPastWeekView